import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import routes from "./routers";
import { ContextMenuTrigger } from "react-contextmenu";

function App() {
  // useEffect(() => {
  //   const disabledDevtools = (e) => {
  //     // disable F12 key
  //     if (e.keyCode === 123) {
  //       e.preventDefault();
  //       return false;
  //     }

  //     // disable I key
  //     if (e.ctrlKey && e.shiftKey && e.keyCode === 73) {
  //       e.preventDefault();
  //       return false;
  //     }

  //     // "S" key + macOS
  //     if (
  //       e.keyCode === 83 &&
  //       (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)
  //     ) {
  //       e.preventDefault();
  //       return false;
  //     }

  //     // disable J key
  //     if (e.ctrlKey && e.shiftKey && e.keyCode === 74) {
  //       e.preventDefault();
  //       return false;
  //     }

  //     // disable U key
  //     if (e.ctrlKey && e.keyCode === 85) {
  //       e.preventDefault();
  //       return false;
  //     }
  //   };
  //   document.addEventListener("keydown", disabledDevtools);

  //   return () => {
  //     document.removeEventListener("keydown", disabledDevtools);
  //   };
  // }, []);
  return (
    <>
      <ContextMenuTrigger id="prevent-context-menu">
        <Routes>
          {routes.map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                element={
                  <React.Fragment>
                    <>
                      <route.component />
                    </>
                  </React.Fragment>
                }
              />
            );
          })}
        </Routes>
      </ContextMenuTrigger>
    </>
  );
}

export default App;
