import { Modal } from "antd";
import styled from "styled-components";

const CustomModal = styled(Modal)`
  .ant-modal-content {
    background-color: #f0f0f0; /* Màu nền */
    border-radius: 10px; /* Bo tròn góc */
  }
`;
const ShowModal = ({ sheetId }) => {
  return (
    <CustomModal
      title="ID Goole Sheets của bạn: "
      open="true"
      footer={null}
      className="modal-container"
      closable={false}
    >
      <span>{sheetId}</span>
    </CustomModal>
  );
};

ShowModal.displayName = "ShowModal";
export default ShowModal;
