import AuthenModal from "./AuthenModal";

function Test() {
  return (
    <>
      <AuthenModal></AuthenModal>
    </>
  );
}
export default Test;
