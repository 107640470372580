import { Modal } from "antd";
import { useState } from "react";
import GoogleButton from "react-google-button";
import { sendGet } from "./axios";
import styled from "styled-components";

const CustomModal = styled(Modal)`
  .ant-modal-content {
    background-color: #f0f0f0; /* Màu nền */
    border-radius: 10px; /* Bo tròn góc */
  }
`;
const AuthenModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(true);

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const getLoginUrl = async () => {
    sendGet(`https://add-on.tools/api/auth/login`).then((res) => {
      window.location.href = res.data.urlLogin;
    });
  };
  return (
    <CustomModal
      title="Đăng nhập bằng tài khoản Google để sử dụng"
      open={isModalOpen}
      onOk={handleOk}
      // onCancel={handleCancel}
      footer={null}
      className="modal-container"
      closable={false}
    >
      <GoogleButton onClick={getLoginUrl} />
    </CustomModal>
  );
};

AuthenModal.displayName = "AuthenModal";
export default AuthenModal;
