import Axios from "axios";

const axiosInstance = Axios.create({
  timeout: 60 * 60 * 1000,
});

axiosInstance.interceptors.request.use(
  (config) => {
    config.headers["Accept"] = "application/json, text/plain, */*";
    config.headers["Accept-Language"] =
      "vi,en;q=0.9,vi-VN;q=0.8,fr-FR;q=0.7,fr;q=0.6,en-US;q=0.5";
    config.headers["Content-Type"] = "application/json";
    config.headers["Access-Control-Allow-Origin"] = "*";
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return 500;
  }
);

export const sendGet = (url, params) =>
  axiosInstance.get(url, { params }).then((res) => {
    if (res === 500) {
      return res;
    }
    return res;
  });

export const sendPost = (url, params, queryParams) =>
  axiosInstance.post(url, params, { params: queryParams }).then((res) => {
    if (res === 500) {
      return res;
    }

    return res?.data;
  });
export const sendPut = (url, params) =>
  axiosInstance.put(url, params).then((res) => res.data);
export const sendPatch = (url, params) =>
  axiosInstance.patch(url, params).then((res) => res.data);
export const sendDelete = (url, params) =>
  axiosInstance.delete(url, { params }).then((res) => res.data);

export const sendDeleteWithBody = (url, payload) =>
  axiosInstance.delete(url, { data: payload }).then((res) => res);
export const sendPostWithStatus = (url, params, queryParams) =>
  axiosInstance.post(url, params, { params: queryParams }).then((res) => res);
export const sendGetWithStatus = (url, params) =>
  axiosInstance.get(url, { params }).then((res) => res);
